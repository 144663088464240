var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center",attrs:{"id":"imprimir"}},[_vm._m(0),_c('div',{staticClass:"justify-content-center",attrs:{"id":"corpo"}},[_c('div',{staticClass:"col-md-12 text-center"},[_c('h4',[_vm._v("OCORRENCIA "+_vm._s(_vm.lista_campos.created))])]),_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("Paciente:"+_vm._s(_vm.paciente.nome_sobrenome))]),_c('p',[_vm._v("Documento:"+_vm._s(_vm.paciente.rg))]),_c('p',[_vm._v("Idade: "+_vm._s(_vm._f("formatDBDateToLocale")(_vm.paciente.data_nascimento)))]),_c('p',[_vm._v(" Sexo: "+_vm._s(_vm.paciente.sexo_id === 2 ? "Feminino" : _vm.paciente.sexo_id === 1 ? "Masculino" : "no declarado")+" ")])]),(_vm.lista_campos.tipo_ocorrencia_id == 1)?_c('h4',{staticClass:"text-center"},[_vm._v(" Triagem ")]):_vm._e(),(_vm.lista_campos.tipo_ocorrencia_id == 2)?_c('h4',[_vm._v("Consulta")]):_vm._e(),(_vm.lista_campos.tipo_ocorrencia_id == 3)?_c('h4',[_vm._v("Observación")]):_vm._e(),(_vm.lista_campos.tipo_ocorrencia_id == 4)?_c('h4',[_vm._v("Receita")]):_vm._e(),(_vm.lista_campos.tipo_ocorrencia_id == 1)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("Motivo:"+_vm._s(_vm.lista_campos.ocorrencia.motivo))])]),_c('div',{staticClass:"col-md-4 col-4"},[_vm._v(" Grado de Urgencia: "),_c('span',{staticClass:"label label-lg label-inline",class:{
              'label-light-success':
                _vm.lista_campos.ocorrencia.grau_urgencia_id === 1,
              yellow: _vm.lista_campos.ocorrencia.grau_urgencia_id === 2,
              'label-light-warning':
                _vm.lista_campos.ocorrencia.grau_urgencia_id === 3,
              'label-light-danger':
                _vm.lista_campos.ocorrencia.grau_urgencia_id === 4,
            }},[_vm._v(" "+_vm._s(_vm.lista_campos.ocorrencia.grau_urgencia_id === 2 ? "Amarillo" : _vm.lista_campos.ocorrencia.grau_urgencia_id === 3 ? "Naranja" : _vm.lista_campos.ocorrencia.grau_urgencia_id === 4 ? "Rojo" : "Verde")+" ")]),_c('p',{staticStyle:{"padding-top":"5px"}},[_vm._v(" Peso: "+_vm._s(_vm.lista_campos.ocorrencia.peso)+" ")]),_c('p',[_vm._v("Glicemia: "+_vm._s(_vm.lista_campos.ocorrencia.glicemia))])]),_c('div',{staticClass:"col-md-4 col-4"},[_c('p',[_vm._v("Saturación: "+_vm._s(_vm.lista_campos.ocorrencia.saturacao))]),_c('p',[_vm._v("Pressión Arterial: "+_vm._s(_vm.lista_campos.ocorrencia.pressao))])]),_c('div',{staticClass:"col-md-4 col-4"},[_c('p',[_vm._v(" Frequencia cardiaca: "+_vm._s(_vm.lista_campos.ocorrencia.freq_cardiaca)+" ")]),_c('p',[_vm._v(" Frequencia respiratoria: "+_vm._s(_vm.lista_campos.ocorrencia.freq_respiratoria)+" ")]),_c('p',[_vm._v("Temperatura: "+_vm._s(_vm.lista_campos.ocorrencia.temperatura))])])])]):_vm._e(),_vm._m(1)]),_vm._m(2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"col-md-12 fixarHeader"},[_c('img',{attrs:{"src":require("../../../../../public/imagens/header1.png"),"width":"100%","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 text-center",staticStyle:{"padding-top":"120px"}},[_vm._v(" _______________________________ "),_c('p',[_vm._v("Responsable")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 pt-6 fixarRodape"},[_c('img',{attrs:{"src":require("../../../../../public/imagens/rodape1.png"),"width":"100%","alt":""}})])}]

export { render, staticRenderFns }