<template>
  <!-- <form method="POST" enctype="multipart/form-data"> -->
  <div class="form-row col-md-11">

    <h5 class="col-md-12 pb-0">Subjective
      <svg v-b-tooltip.hover.right
        title=" Describe la información subjetiva proporcionada por el paciente o el cuidador: síntomas que el paciente reporta, su historia médica, su historial de enfermedades y cualquier otra información relevante que el paciente pueda ofrecer. Esta sección se centra en lo que el paciente siente y experimenta."
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
        <path d="M12 9h.01" />
        <path d="M11 12h1v4h1" />
      </svg>
      <hr class="col-md-12 pb-0" />
    </h5>

    <div class="form-group col-md-9">
      <label class="col-md-12">{{ $t("MOTIVO_CONSULTA.NOME") }}</label>
      <!-- <SelectAutocomplete></SelectAutocomplete> -->
      <input required type="text" name="motivo" class="form-control" v-model="form.motivo" placeholder="Escribe..." />
    </div>

    <div class="form col-md-3 pt-3">
      <div class="form-group pt-5">
        <div class="form-check form-check-custom form-check-success form-check-solid">
          <input class="form-check-input" value="1" v-model="form.merece_fe" type="radio" name="plan" />
          <label class="form-check-label"> Merece fe</label>
        </div>

        <div class="form-check form-check-custom form-check-danger form-check-solid">
          <input class="form-check-input" value="0" v-model="form.merece_fe" type="radio" name="plan" />
          <label class="form-check-label" for="">No merece fe</label>
        </div>
      </div>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12">{{ $t("DESCRIP_DATOS.NOME") }}</label>
      <input required type="text" class="form-control" v-model="form.dados_referidos" placeholder="Escribe..." />
    </div>

    <!-- <div class="form-group col-md-12">
      <label class="col-md-12">{{ $t("HDA.NOME") }}</label>
      <textarea required :rows="calculateRows(form.antecedentes_aea)" type="text" class="form-control"
        v-model="form.antecedentes_aea" placeholder="Escribe..."></textarea>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12">{{ $t("HRDA.NOME") }}</label>
      <textarea :autosize="true" :rows="calculateRows(form.antecedentes_area)" required type="text" class="form-control"
        v-model="form.antecedentes_area" placeholder="Escribe..."></textarea>
    </div> -->

    <div class="form-group col-md-12">
      <label class="col-md-12"> {{ $t("INTERVENCION.NOME") }}*</label>
      <textarea :rows="calculateRows(form.antecedentes_cirurgicos)" cols="40" required type="text" class="form-control"
        v-model="form.antecedentes_cirurgicos" placeholder="Escribe..."></textarea>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12">
        Antecedente Patologico Personal (A.P.P):</label>
      <textarea :autosize="true" :rows="calculateRows(form.antecedentes_app)" required type="text" class="form-control"
        v-model="form.antecedentes_app" placeholder="Escribe..."></textarea>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12">
        Antecedente Patologico Familiar (A.P.F):</label>
      <textarea :rows="calculateRows(form.antecedentes_apf)" cols="40" required type="text" class="form-control"
        v-model="form.antecedentes_apf" placeholder="Escribe..."></textarea>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12"> Alergia a Medicamentos (R.A.M):*</label>
      <input required type="text" class="form-control" :rows="calculateRows(form.alergias)" name="alergias"
        v-model="form.alergias" placeholder="Escribe..." />
    </div>


    <h5 class="col-md-11 pb-0">Objective
      <svg v-b-tooltip.hover.right
        title="Aquí, se registran los hallazgos objetivos y observables del médico o profesional de la salud. Esto puede incluir resultados de exámenes físicos, pruebas de laboratorio, radiografías u otros estudios diagnósticos. Es la información que se obtiene a través de la evaluación clínica y las pruebas médicas."
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
        <path d="M12 9h.01" />
        <path d="M11 12h1v4h1" />
      </svg>
      <hr class="col-md-12 pb-0" />
    </h5>


    <div class="form-group col-md-12">
      <label class="col-md-12">{{ $t("EXAMENES.NOME") }}*</label>
      <textarea :rows="calculateRows(form.examen_fisico)" required type="text" class="form-control"
        v-model="form.examen_fisico" placeholder="Escribe..."></textarea>
    </div>

    <div class="col-md-12 ">
      <label class="col-md-12">Adjunte imágenes o archivos de examen aquí, caso necesario:</label>
      <div class="table-responsive">
        <table class="table table-sm table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="fw-bold fs-6 text-gray-800">
              <th>Leyenda del archivo</th>
              <th>Archivo(jpg/jepg/pdf)</th>
              <th>
                <button v-if="lista_campos == ''" @click="add_arquivo = true" title="Agregar archivo"
                  v-b-tooltip.hover.right class="btn btn-icon btn-primary float-right btn-sm mx-1">
                  <span class="fas fa-plus"></span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lista, index) in lista_arquivos" :key="index">
              <td>{{ lista.nome }}</td>
              <td>{{ lista.file.name }}</td>
              <td class="text-right">
                <button v-if="lista_campos == ''" @click="preVisualizarArquivo(index)" title="Pré-visualizar imagen"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover>
                  <i class="fa fa-eye icon-sm text-info"></i>
                </button>
                <button v-if="lista_campos = ''" @click="confirm('exclui', index)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Retirar ">
                  <i class="ki ki-close icon-sm text-danger"></i>
                </button>

                <button v-if="lista_campos != ''" @click="visualizarArquivo(lista)" title="Visualizar imagen"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover.left>
                  <i class="fa fa-eye icon-sm text-success"></i>
                </button>
                <a @click="downloadArquivo(lista)" v-if="lista_campos != ''" class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover.left title="Download">
                  <i class="fa fa-download icon-sm text-warning"></i>
                </a>
                <button v-if="lista_campos != ''" @click="confirm('exclui', index)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover.left title="Retirar ">
                  <i class="ki ki-close icon-sm text-danger"></i>
                </button>

              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div v-if="add_arquivo == true" class="form-group row">
        <div class="form-group col-md-6">
          <input required type="text" v-model="arquivo.nome" class="form-control"
            placeholder="Digite un nombre para el archivo..." />
        </div>
        <div class="form-group col-md-6">
          <input required type="file" class="form-control" multiple="multiple" @change="onFileSelected"
            placeholder="Escribe..." />
        </div>
        <div class="form-group col-md-12 text-right">
          <button class="btn btn-sm btn-primary" type="button" @click="addArquivo">salvar</button>
        </div>
      </div>

      <b-modal ref="pre_visualizar_img" centered id="pre_visualizar_img" title="Visualizar imagen">

        <img :src="imagemSelecionada ? createObjectURL(imagemSelecionada) : ''"
          :style="{ maxWidth: '100%', maxHeight: '100%' }">

      </b-modal>
      <b-modal ref="visualizar_img" centered id="pre_visualizar_img" title="Visualizar imagen">
        <img :src="link_img" alt="Imagem" style="max-width: 100%; max-height:100%;">
      </b-modal>
      <b-modal ref="visualizar_pdf" centered id="pdf" title="Visualizar arquivo">
        <pdf :src="link_img" />
      </b-modal>
    </div>

    <h5 class="col-md-11 pb-0">Assessment (Evaluación)
      <svg v-b-tooltip.hover.right
        title="Aquí, se registran los hallazgos objetivos y observables del médico o profesional de la salud. Esto puede incluir resultados de exámenes físicos, pruebas de laboratorio, radiografías u otros estudios diagnósticos. Es la información que se obtiene a través de la evaluación clínica y las pruebas médicas."
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
        <path d="M12 9h.01" />
        <path d="M11 12h1v4h1" />
      </svg>
      <hr class="col-md-12 pb-0" />
    </h5>

    <div class="form-group col-md-12">
      <label class="col-md-12">Diagnóstico principal:*
        <span v-b-tooltip.hover
          title="Selecionar diagnostico principal del paciente conforme CIE-10. Es importante vincular el paciente a un diagnostico porque despues podemos filtrar consultas por diagnosticos"><svg
            xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
            class="bi bi-question-circle-fill" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
          </svg></span>
      </label>
      <div>
        <b-dropdown split :text="texto" ref="select_diagnostico" class="m-2">
          <div class="navi navi-hover min-w-md-350px">
            <b-dropdown-text tag="div" class="navi-item">
              <input v-model="input_pesquisa" type="text" class="form-control" v_model="pesquisa_diagnostico" i />
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-separator my-3"></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <b-table :per-page="perPage" :current-page="currentPage" borderless small hover
                :items="lista_diagnosticos" :fields="fields">
                <template #cell(selecionar)="{ item }">
                  <button @click="selecionarDiagnostico(item)" class="btn btn-light-primary">
                    ok
                  </button>
                </template>
              </b-table>
              <div>
                <b-pagination v-model="currentPage" :total-rows="lista_diagnosticos.length" size="sm"></b-pagination>
              </div>
            </b-dropdown-text>
          </div>
        </b-dropdown>
      </div>
    </div>

    <div class="form-group col-md-12">
      <label class="col-md-12">Descripcion Diagnóstico:*</label>
      <textarea rows="5" cols="40" required type="text" class="form-control" v-model="form.diagnostico"
        placeholder="Escribe..."></textarea>
    </div>

    <h5 class="col-md-11 pb-0">Plan
      <svg v-b-tooltip.hover.right
        title="Aquí, se detallan las acciones que se tomarán para abordar los problemas médicos identificados en la evaluación. Esto puede incluir prescripciones de medicamentos, procedimientos médicos, terapias, recomendaciones de estilo de vida, seguimiento médico, y cualquier otra acción necesaria para la atención del paciente."
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
        <path d="M12 9h.01" />
        <path d="M11 12h1v4h1" />
      </svg>
      <hr class="col-md-12 pb-0" />
    </h5>

    <div class="form-group col-md-12">
      <label class="col-md-12">{{ $t("CONDUCTA.NOME") }}*</label>
      <textarea rows="5" cols="40" required type="text" class="form-control" v-model="form.conduta"
        placeholder="Escriba la conducta a ser tomada..."></textarea>
    </div>

    <div class="col-md-11 mt-2">
      <button @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')" class="btn btn-primary float-right"
        :disabled="verif">
        {{ $t("GERENCIAR.NOME") }}
        <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
      </button>
    </div>

  </div>
  <!-- </form> -->
</template>

<script>
import pdf from 'vue-pdf'
import Swal from "sweetalert2";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { drowpdow } from "../../content/dropdown/Dropdown1.vue";
import SelectAutocomplete from "./componentes/selectAutocomplete.vue"
export default {
  components: { drowpdow, SelectAutocomplete,pdf},
  mixins: [fireAlert],
  data() {
    return {
      verif: false,
      //info tabela pesquisa diagnostico
      texto: "Selecionar Diagnostico Principal conforme CIE-10",
      fields: ["codigo", "nome", "selecionar"],
      perPage: 5,
      currentPage: 3,
      input_pesquisa: "",
      //fim info diagnostico
      form: {
        antecedentes_aea: "-",
        antecedentes_area: "-",
        antecedentes_app: "",
        antecedentes_apf: "",
        antecedentes_cirurgicos: "",
        alergias: "",
        examen_fisico: "",
        motivo: "",
        merece_fe: true,
        diagnostico: "",
        conduta: "",
        atendimento_id: "",
        diagnostico_id: "",
        arquivos: [],
        nomes_arquivos: []
      },
      add_arquivo: false,
      lista_arquivos: [],
      arquivo: {
        nome: null,
        file: null
      },
      radio: null,
      imagemSelecionada: null,
      carregar_img:false,
 

    };
  },
  watch: {
    input_pesquisa() {
      if (this.input_pesquisa.length >= 3) {
        this.listar_diagnostico();
      }
    },
    lista_campos() {
      this.preenxerCampos();
    }
  },
  computed: {
    dados_diagnostico() {
      return this.$store.state.ocorrencia.dados_diagnostico;
    },
    lista_arquivos_consulta() {
      return this.$store.state.ocorrencia.lista_arquivos_consulta;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_diagnosticos() {
      return this.$store.state.configEmpresa.lista_diagnosticos;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
    link_img() {
      return this.$store.state.ocorrencia.link_img
    }
  },
  created() {
    this.preenxerCampos();

    if (window.URL) {
      this.createObjectURL = window.URL.createObjectURL;
    } else if (window.webkitURL) { // Para navegadores mais antigos baseados no WebKit
      this.createObjectURL = window.webkitURL.createObjectURL;
    }

    

  },

  methods: {
    //#region inicio funções add imagens
    onFileSelected(event) {
      console.log(event)
      this.arquivo.file = event.target.files[0];
    },
    async addArquivo() {
      var item = {
        nome: this.arquivo.nome,
        file: this.arquivo.file
      }
      await this.lista_arquivos.push(item)
      this.arquivo.nome = null,
        this.arquivo.file = null,
        this.add_arquivo = false
      console.log(this.lista_arquivos)

    },
    removerArquivo(index) {
      this.$delete(this.lista_arquivos, index);
    },
    preVisualizarArquivo(index) {
      this.imagemSelecionada = this.lista_arquivos[index].file;
      this.$refs["pre_visualizar_img"].show();
    },
    //#endregion fim funções add imagens

    //visualizar imagens ja anexadas
    async visualizarArquivo(item) {
      console.log(item.file.type)

      try {
        await this.$store
          .dispatch("ocorrencia/visualizarArquivos", item)

        console.log(test)
      } catch (error) {
        console.log(error)
      }
      console.log(this.link_img)//link que retorna da api laravel: blob:http://localhost:8080/4a2c0139-9384-45b5-859b-ce76f57bd5f1

      // this.imagemSelecionada = this.lista_arquivos[index].file;
      if (item.file.type != "application/pdf") {
        this.$refs["visualizar_img"].show()
      } else {
        this.$refs["visualizar_pdf"].show()
      }

    },

    async downloadArquivo(item) {
      
      console.log(item)
      await this.$store
        .dispatch("ocorrencia/downloadArquivos", item)
        .finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          this.verif = false;
          this.alertError()

        });
    },

    calculateRows(text) {
      const lineCount = text.split('\n').length;
      const minRows = 1;
      return Math.max(minRows, lineCount);
    },

    async confirm(tipo, value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un atendimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
            if (tipo == "exclui") this.removerArquivo(value)
          }
        },
      });
    },

    async create() {
      const { atendimento_id } = this.$route.params
      this.form.atendimento_id = atendimento_id
      const formData = new FormData();

      // Adiciona os campos de texto ao FormData
      // Object.keys(this.form).forEach(key => {
      //   console.log(key)
      //   formData.append(key, this.form[key]);
      // });

      const json = JSON.stringify({
        antecedentes_aea: this.form.antecedentes_aea,
        antecedentes_area: this.form.antecedentes_area,
        antecedentes_app: this.form.antecedentes_app,
        antecedentes_apf: this.form.antecedentes_apf,
        antecedentes_cirurgicos: this.form.antecedentes_cirurgicos,
        alergias: this.form.alergias,
        examen_fisico: this.form.examen_fisico,
        motivo: this.form.motivo,
        dados_referidos: this.form.dados_referidos,
        merece_fe: this.form.merece_fe,
        diagnostico: this.form.diagnostico,
        conduta: this.form.conduta,
        atendimento_id: this.form.atendimento_id,
        diagnostico_id: this.form.diagnostico_id,
        arquivos: this.form.arquivos,
        nomes_arquivos: this.form.nomes_arquivos
      });
      formData.append('dados', json)


      // Adiciona os arquivos ao FormData
      this.lista_arquivos.forEach(arquivo => {
        formData.append('arquivos[]', arquivo.file);
      });

      this.lista_arquivos.forEach(arquivo => {
        formData.append('nomes_arquivos[]', arquivo.nome);
      });

      console.log(formData)
      this.verif = true;

      await this.$store
        .dispatch("ocorrencia/create_consulta", formData)
        .finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          this.verif = false;
          this.alertError()

        });

    },

    alertError() {
      if (this.mensagem_alert.tipo == "error") {
        var texto = "Verificar los campos: "
        var erros = this.mensagem_alert.message.errors
        console.log("erros", erros)

        if (erros.hasOwnProperty('alergias')) {
          texto = texto + 'ALERGIAS, '
        }
        if (erros.hasOwnProperty('antecedentes_aea')) {
          texto = texto + 'Antecedentes de la Enfermedad Actual, '
        }
        if (erros.hasOwnProperty('antecedentes_apf')) {
          texto = texto + 'Antecedente Patologico Familiar (A.P.F):, '
        }
        if (erros.hasOwnProperty('antecedentes_app')) {
          texto = texto + 'Antecedente Patologico Personal (A.P.P):, '
        }
        if (erros.hasOwnProperty('antecedentes_area')) {
          texto = texto + 'Antecedentes Remotos de Enfermedad Actual (A.R.E.A), '
        }
        if (erros.hasOwnProperty('conduta')) {
          texto = texto + 'Conducta, '
        }

        if (erros.hasOwnProperty('diagnostico_id')) {
          texto = texto + 'Diagnostico Principal(CIE-10), '
        }
        if (erros.hasOwnProperty('diagnostico')) {
          texto = texto + 'Desccripción Dianostico, '
        }
        if (erros.hasOwnProperty('dados_referidos')) {
          texto = texto + 'datos referidos, '
        }
        if (erros.hasOwnProperty('examen_fisico')) {
          texto = texto + 'Examen fisico, '
        }
        if (erros.hasOwnProperty('motivo')) {
          texto = texto + 'Motivo de consulta, '
        }
        if (erros.hasOwnProperty('merece_fe')) {
          texto = texto + 'Merece fe, '
        }
        if (erros.hasOwnProperty('antecedentes_cirurgicos')) {
          texto = texto + 'Antecedentes de Intervenciones quirúrgicas, '
        }
        var dados = {
          tipo: "Consulta",
          fechar: false,
        }
        this.$store.dispatch("ocorrencia/save_dados_modais", dados)

        Swal.fire({
          title: 'Problema con los datos!',
          text: texto,
          footer: 'Todos los campos son obrigatorios, puedes poner un "-" o "niega" si no hay antecedentes',
          confirmButtonText: "Terminar de llenar",
          confirmButtonColor: '#ff6060',
          // customClass: {
          //   icon: "center",
          // },
        });

      } else {
        this.fireAlert(this.mensagem_alert.message)
        var dados = {
          tipo: "Consulta",
          fechar: true,
        }
        this.$store.dispatch("ocorrencia/save_dados_modais", dados)
      }
    },

    async update() {
      const { atendimento_id } = this.$route.params;
      this.form.atendimento_id = atendimento_id;
      this.verif = true;
      await this.$store
        .dispatch("ocorrencia/update_consulta", this.form)
        .finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          this.verif = false;
          this.fireAlert(this.mensagem_alert.message);
        });
    },

    async listar_diagnostico() {
      await this.$store.dispatch(
        "configEmpresa/pesquisar_diagnosticos",
        this.input_pesquisa
      );
      console.log(this.lista_diagnosticos);
    },

    async listar_arquivos(consulta_id) {
      await this.$store.dispatch(
        "ocorrencia/listar_arquivos_consulta",
        consulta_id
      );
      console.log(this.lista_arquivos_consulta);
    },

    selecionarDiagnostico(item) {
      this.texto = item.codigo + ' - ' + item.nome
      this.form.diagnostico_id = item.id
      this.$refs.select_diagnostico.hide(true)
    },
    async preenxerCampos() {
      if (this.lista_campos != "") {
        var val = this.lista_campos;
        this.form = val.ocorrencia;
        this.form.tipo_ocorrencia_id = val.tipo_ocorrencia_id;
        this.form.grau_urgencia_id = val.grau_urgencia_id;
        this.form.ocorrencia_id = val.id;

        if (this.lista_campos.ocorrencia.diagnostico_id != null) {
          await this.$store.dispatch('ocorrencia/getDiagnosticoId', this.lista_campos.ocorrencia.diagnostico_id)
          this.texto = this.dados_diagnostico.codigo + ' - ' + this.dados_diagnostico.nome
        } else {
          this.texto = "Selecionar Diagnostico Principal conforme CIE-10"
        }

        await this.listar_arquivos(val.ocorrencia_id)
        if (this.lista_arquivos_consulta.length > 0) {
          for (let i = 0; i < this.lista_arquivos_consulta.length; i++) {
            const element = this.lista_arquivos_consulta[i];
            var arquivo = {
              id: element.id,
              nome: element.titulo,
              file: {
                name: element.original_name,
                size: element.size,
                type: element.type
              }
            }
            this.lista_arquivos.push(arquivo)

          }

        }

      }
    },
  },


  //  watch:{
  //    lista_campos: function (val) {

  //     this.form = val.ocorrencia
  //     this.form.tipo_ocorrencia_id = val.tipo_ocorrencia_id
  //        this.form.grau_urgencia_id = val.grau_urgencia_id
  //   },
  // }
};
</script>

<style>
.hr-custom {
  border: none;
  /* Remove a borda padrão do hr */
  height: 1px;
  /* Define a altura da linha */
  background-color: #7c2323;
  /* Define a cor da linha */
  margin: 20px 0;
  /* Opcional: ajusta a margem para posicionamento */
}
</style>
