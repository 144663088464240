<template>
  <div class="row justify-content-center" id="imprimir" style="background-color: white">
    <header class="col-md-12 fixarHeader">
      <img src="../../../../../public/imagens/header2.png" width="100%" alt="" />
    </header>
    <div id="corpo" class="justify-content-center">
      <div class="col-md-12 text-center">
        <h4>FICHA DEL PACIENTE </h4>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <p><b>Paciente</b>: {{ paciente.nome_sobrenome }}</p>

            <p>
              <b>Sexo</b>:
              {{
                paciente.sexo_id === 4
                ? "Feminino"
                : paciente.sexo_id === 3
                  ? "Masculino"
                  : "no declarado"
              }}
            </p>
          </div>
          <div class="col-md-6">
            <p><b>Documento:</b> {{ paciente.rg }}</p>
            <p>
              <b>Sexo</b>:
              {{
                paciente.sexo_id === 4
                ? "Feminino"
                : paciente.sexo_id === 3
                  ? "Masculino"
                  : "no declarado"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12">

        <h4 class="text-center" v-if="lista_campos.tipo_ocorrencia_id == 2">
          Consulta Medica {{ lista_campos.created }}
        </h4>


        <div v-if="lista_campos.tipo_ocorrencia_id == 2" class="col-md-12 pt-1" id="info_consulta">
          <div class="row">
            <div class="col-md-12">
              <p><b>Motivo: </b> {{ lista_campos.ocorrencia.motivo }}</p>
            </div>

            <div class="col-md-12 col-12">
              <p><b>Alergias: </b> {{ lista_campos.ocorrencia.alergias }}</p>
            </div>

            <div class="col-md-12 col-12">
              <p><b>Antecedentes A.E.A: </b> {{ lista_campos.ocorrencia.antecedentes_aea }}</p>
              <p><b>Antecedentes A.P.F: </b>{{ lista_campos.ocorrencia.antecedentes_apf }}</p>
              <p>
                <b>Antecedentes A.P.P: </b>

                {{ lista_campos.ocorrencia.antecedentes_app }}
              </p>
              <p><b>Antecedentes AREA: </b>{{ lista_campos.ocorrencia.antecedentes_area }}</p>
              <p>
                <b>Antecedentes Quirurgicos: </b>
                {{ lista_campos.ocorrencia.antecedentes_cirurgicos }}
              </p>
              <p><b>Examen Fisico: </b>{{ lista_campos.ocorrencia.examen_fisico }}</p>
              <p><b>Diagnostico: </b>{{ lista_campos.ocorrencia.diagnostico }}</p>
              <p><b>Conducta: </b>{{ lista_campos.ocorrencia.conduta }}</p>
            </div>
          </div>
        </div>

      </div>


      <div class="col-md-12 text-center" style="padding-top: 100px">
        _______________________________
        <p>Responsable</p>
      </div>
    </div>
    <div class="col-md-12 pt-6 fixarRodape">
      <img src="../../../../../public/imagens/rodape1.png" width="100%" alt="" />
    </div>

  </div>
</template>
<style>
.yellow {
  background-color: rgb(255, 255, 189);
  color: rgb(224, 224, 0);
}

@media print {

  /* esconder tudo */
  @page {
    background-color: rgb(255, 255, 255);
  }

  body * {
    visibility: hidden;
  }

  /* exibir somente o que tem na div para imprimir */
  #imprimir,
  #imprimir * {
    visibility: visible;
    font-size: 17px;
    width: 1150px;

  }

  p * {
    font-size: 14px;
  }

  .h4 {
    text-align: center;
  }

  #imprimir {
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    height: 70cm;
    width: 100%;
  }

  #info_consulta {
    width: 80%;
  }

  .assinatura {
    padding-top: 10px;
    width: 100%;
    bottom: 300px;
    position: fixed;
  }

  .fixarRodape {
    bottom: 0;
    position: fixed;
  }

  .fixarHeader {
    top: 8px;
    position: fixed;
  }

  #corpo {
    top: 10px;
    padding-top: 20%;
    width: 90%;
    align-items: center;
    align-content: center;
  }

  /* .fixarHeader {
      top: 0;
      width: 100%;
      position: fixed;
  } */
}
</style>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { isThisMonth } from "date-fns/esm";

export default {
  mixins: [fireAlert],
  //  props:{

  //  },
  data() {
    return {
      verif: false,
      classe: "",
      form: {
        temperatura: "",
        freq_cardiaca: "",
        freq_respiratoria: "",
        peso: "",
        altura: "",
        pressao: "",
        saturacao: "",
        motivo: "",
        grau_urgencia_id: "",
        tipo_ocorrencia_id: "",
        atendimento_id: "",
        glicemia: "",
        lista: {
          text: "",
        },
      },
    };
  },
  created() {
    this.lista_usuarios();
    this.form = this.lista_campos;
    console.log(this.lista_campos);
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    paciente() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
  },

  methods: {
    async lista_usuarios() {
      await this.$store.dispatch(
        "configUsuarios/listar_usuario",
        this.lista_campos.paciente_id
      );
    },
  },
};
</script>
