<template>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label class="col-md-12"> Motivo Consulta (MC):*</label>
      <input required type="text" class="form-control" v-model="form.motivo" placeholder="Escribe el motivo..." />
    </div>
    <div class="col-md-4">
      <label class="col-md-12"> Peso(Kg):*</label>
      <input required type="text" class="form-control" v-model="form.peso" placeholder="Escribe el Peso(Kg)..." />
    </div>
    <div class="form-group col-md-4">
      <label class="col-md-12"> Altura(m):*</label>
      <input required type="text" class="form-control" v-model="form.altura"
        placeholder="Escribe la Altura(metros)..." />
    </div>
    <div class="col-md-4">
      <label class="col-md-12">IMC</label>
      <input disabled type="text" class="form-control" :class="{
        Verde: cor_imc === 1,
        Amarillho: cor_imc === 2,
        Naranja: cor_imc === 3,
        Rojo: cor_imc === 4,
      }" v-model="imc" placeholder="Escribe el Peso(Kg)..." />
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-12"> Temperatura(°C):*</label>
      <input required type="text" class="form-control" v-model="form.temperatura"
        placeholder="Escribe la temperatura..." />
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-12"> {{ $t("PRESSAO.NOME") }}*:</label>
      <input required type="text" class="form-control" v-model="form.pressao" placeholder="Escribe la presión..." />
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-12"> {{ $t("SATURACAO.NOME") }}*:</label>
      <input required type="text" class="form-control" v-model="form.saturacao"
        placeholder="Escribe la saturación..." />
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-12"> Glicemia(mg/dL)*:</label>
      <input required type="text" class="form-control" v-model="form.glicemia" />
    </div>

    <div class="form-group col-md-6">
      <label class="col-md-12">{{ $t("FRE.NOME") }}*:</label>
      <input required type="text" class="form-control" v-model="form.freq_cardiaca" />
    </div>
    <div class="form-group col-md-6">
      <label class="col-md-12">{{ $t("RESPIRATORIA.NOME") }}*:</label>
      <input required type="text" class="form-control" v-model="form.freq_respiratoria" />
    </div>

    <div class="col-md-5">
      <b-form-group label="Grado de Urgencia:*">
        <!-- <b-form-select
          :options="lista_grau_urgencia"
          v-model="form.grau_urgencia_id"
          required
        ></b-form-select> -->
        <select required type="text" class="form-control" :class="{
        Verde: form.grau_urgencia_id === 1,
        Amarillo: form.grau_urgencia_id === 2,
        Naranja: form.grau_urgencia_id === 3,
        Rojo: form.grau_urgencia_id === 4,
      }" v-model="form.grau_urgencia_id">
          <option v-for="lista in lista_grau_urgencia" :key="lista.id" :value="lista.id" :class="lista.nome">
            {{ lista.nome }}
          </option>
        </select>
      </b-form-group>
    </div>

    <div class="col-md-11 mt-2">
      <button @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')" class="btn btn-primary float-right"
        :disabled="verif">
        {{ $t("SALVAR.NOME") }}
        <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
      </button>
    </div>
  </div>
</template>
<style>
.Rojo {
  background-color: rgb(255, 202, 202);
  color: red;
}

.Amarillo {
  background-color: rgb(255, 255, 189);
  color: rgb(224, 224, 0);
}

.Verde {
  background-color: rgba(192, 247, 192, 0.974);
  color: rgb(3, 55, 3);
}

.Naranja {
  background-color: rgb(255, 232, 190);
  color: rgb(244, 159, 3);
}
</style>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  //  props:{

  //  },
  data() {
    return {
      verif: false,
      classe: "",
      form: {
        temperatura: "",
        freq_cardiaca: "",
        freq_respiratoria: "",
        peso: null,
        altura: null,
        pressao: "",
        saturacao: "",
        motivo: "",
        grau_urgencia_id: null,
        tipo_ocorrencia_id: 1,
        atendimento_id: "",
        glicemia: "",
        lista: {
          text: "",
        },
      },
      imc: "",
      cor_imc: 1,
    };
  },
  computed: {
    lista_tipo_ocorrencia() {
      let option = [];
      option.push({ value: null, text: "Seleccione" });
      this.$store.state.ocorrencia.lista_tipo_ocorrencia.forEach((element) => {
        option.push({ value: element.id, text: element.nome });
      });
      return option;
    },
    lista_grau_urgencia() {
      return this.$store.state.ocorrencia.lista_grau_urgencia;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
  },
  created() {
    this.preenxerCampos();
    this.listar_requerimentos();
  },
  watch: {
    lista_campos() {
      this.preenxerCampos();
    },
    'form.peso'() {
      this.calcularIMC()
    },
    'form.altura'(value) {
      this.formatarAltura()
      this.calcularIMC()
    },
  },
  methods: {
    formatarAltura() {
      let alturaNumerica = this.form.altura.replace(/\D/g, '');

      // Formata a altura adicionando uma vírgula e duas casas decimais
      let alturaFormatada = alturaNumerica.replace(/(\d)(\d{2})$/, '$1,$2');

      // Atualiza o valor da altura com a formatação
      this.form.altura = alturaFormatada;
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un atendimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      const { atendimento_id } = this.$route.params;
      let data = { atendimento_id: atendimento_id };
      this.form.atendimento_id = atendimento_id;
      this.verif = true;
      await this.$store
        .dispatch("ocorrencia/create_triagem", this.form)
        .finally(() => {
          this.verif = false;

          if (this.mensagem_alert.tipo === "error") {
            var dados = {
              tipo: "Triagem",
              fechar: false,
            };
          } else {
            var dados = {
              tipo: "Triagem",
              fechar: true,
            };
          }
          this.$store.dispatch("ocorrencia/save_dados_modais", dados);
          this.fireAlert(this.mensagem_alert);
          console.log("Dados triagem", dados);
        });
      this.$store.dispatch("atendimento/lista_atendimento_ocorrencias", data);
    },
    async calcularIMC() {

      if (this.form.peso != null && this.form.altura != null && this.form.altura != "" && this.form.peso != "") {
        var alt = this.form.altura.replace(/\D/g, '');
        var altura = alt.replace(/(\d)(\d{2})$/, '$1.$2');
        console.log(altura)
        console.log('okok')
        var imc = this.form.peso / (altura * altura)
        if (imc < 18.5) {
          this.imc = imc.toFixed(2) + ' (Bajo Peso)'
          this.cor_imc = 1
        } else if (imc < 25.00) {
          this.imc = imc.toFixed(2) + ' (Normal)'
          this.cor_imc = 2
        } else if (imc < 29.9) {
          this.imc = imc.toFixed(2) + ' (Sobrepeso)'
          this.cor_imc = 3
        } else {
          this.imc = imc.toFixed(2) + ' (Obesidad)'
          this.cor_imc = 4
        }
      }

    },
    async update() {
      const { atendimento_id } = this.$route.params;
      this.form.atendimento_id = atendimento_id;
      this.verif = true;
      console.log(this.form);
      await this.$store
        .dispatch("ocorrencia/update_triagem", this.form)
        .finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          this.verif = false;
          this.fireAlert(this.mensagem_alert);
        });
    },

    listar_requerimentos() {
      this.$store.dispatch("ocorrencia/listar_tipo_ocorrencia");
      this.$store.dispatch("ocorrencia/listar_grau_urgencia");
    },

    preenxerCampos() {
      if (this.lista_campos != "") var val = this.lista_campos;
      this.form = val.ocorrencia;

      this.form.tipo_ocorrencia_id = val.tipo_ocorrencia_id;
      this.form.grau_urgencia_id = val.grau_urgencia_id;
      this.form.ocorrencia_id = val.id;
      // // this.form.saturacao = val.saturacao
    },
  },

};
</script>
