<template >
  <div class="row justify-content-center" id="imprimir">
    <header class="col-md-12 fixarHeader">
      <img
        src="../../../../../public/imagens/header1.png"
        width="100%"
        alt=""
      />
    </header>
    <div id="corpo" class="justify-content-center">
      <div class="col-md-12 text-center">
        <h4>OCORRENCIA {{ lista_campos.created }}</h4>
      </div>
      <div class="col-md-12">
        <p>Paciente:{{ paciente.nome_sobrenome }}</p>
        <p>Documento:{{ paciente.rg }}</p>
        <p>Idade: {{ paciente.data_nascimento | formatDBDateToLocale }}</p>
        <p>
          Sexo:
          {{
            paciente.sexo_id === 2
              ? "Feminino"
              : paciente.sexo_id === 1
              ? "Masculino"
              : "no declarado"
          }}
        </p>
      </div>
      <h4 class="text-center" v-if="lista_campos.tipo_ocorrencia_id == 1">
        Triagem
      </h4>
      <h4 v-if="lista_campos.tipo_ocorrencia_id == 2">Consulta</h4>
      <h4 v-if="lista_campos.tipo_ocorrencia_id == 3">Observación</h4>
      <h4 v-if="lista_campos.tipo_ocorrencia_id == 4">Receita</h4>

      <!-- DADOS TRIAGEM -->
      <div v-if="lista_campos.tipo_ocorrencia_id == 1" class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <p>Motivo:{{ lista_campos.ocorrencia.motivo }}</p>
          </div>
          <div class="col-md-4 col-4">
            Grado de Urgencia:
            <span
              class="label label-lg label-inline"
              :class="{
                'label-light-success':
                  lista_campos.ocorrencia.grau_urgencia_id === 1,
                yellow: lista_campos.ocorrencia.grau_urgencia_id === 2,
                'label-light-warning':
                  lista_campos.ocorrencia.grau_urgencia_id === 3,
                'label-light-danger':
                  lista_campos.ocorrencia.grau_urgencia_id === 4,
              }"
            >
              {{
                lista_campos.ocorrencia.grau_urgencia_id === 2
                  ? "Amarillo"
                  : lista_campos.ocorrencia.grau_urgencia_id === 3
                  ? "Naranja"
                  : lista_campos.ocorrencia.grau_urgencia_id === 4
                  ? "Rojo"
                  : "Verde"
              }}
            </span>
            <p style="padding-top: 5px">
              Peso: {{ lista_campos.ocorrencia.peso }}
            </p>
            <p>Glicemia: {{ lista_campos.ocorrencia.glicemia }}</p>
          </div>
          <div class="col-md-4 col-4">
            <p>Saturación: {{ lista_campos.ocorrencia.saturacao }}</p>

            <p>Pressión Arterial: {{ lista_campos.ocorrencia.pressao }}</p>
          </div>
          <div class="col-md-4 col-4">
            <p>
              Frequencia cardiaca: {{ lista_campos.ocorrencia.freq_cardiaca }}
            </p>
            <p>
              Frequencia respiratoria:
              {{ lista_campos.ocorrencia.freq_respiratoria }}
            </p>
            <p>Temperatura: {{ lista_campos.ocorrencia.temperatura }}</p>
          </div>
        </div>
      </div>

      <div class="col-md-12 text-center" style="padding-top: 120px">
        _______________________________
        <p>Responsable</p>
      </div>

    </div>
    <div class="col-md-12 pt-6 fixarRodape">
      <img
        src="../../../../../public/imagens/rodape1.png"
        width="100%"
        alt=""
      />
    </div>
  </div>
</template>
<style>
.yellow {
  background-color: rgb(255, 255, 189);
  color: rgb(224, 224, 0);
}
@media print {
  /* esconder tudo */
  body * {
    visibility: hidden;
    background-color: white;
  }
  /* exibir somente o que tem na div para imprimir */
  #imprimir,
  #imprimir * {
    width: 100%;
    visibility: visible;
    background-color: white;
  }
  p * {
    font-size: 14px;
  }
  .h4 {
    text-align: center;
  }

  .fixarRodape {
    bottom: 0;
    position: fixed;
  }

  #corpo {
    top: 10px;
    width: 90%;
    align-items: center;
    align-content: center;
  }
  .fixarHeader {
      top: 0;
      width: 100%;
      position: fixed;
  }
}
</style>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  //  props:{

  //  },
  data() {
    return {
      verif: false,
      classe: "",
      form: {
        temperatura: "",
        freq_cardiaca: "",
        freq_respiratoria: "",
        peso: "",
        altura: "",
        pressao: "",
        saturacao: "",
        motivo: "",
        grau_urgencia_id: "",
        tipo_ocorrencia_id: "",
        atendimento_id: "",
        glicemia: "",
        lista: {
          text: "",
        },
      },
    };
  },
  created() {
    this.lista_usuarios();
    this.form = this.lista_campos;
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    paciente() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
  },

  methods: {
    async lista_usuarios() {
      await this.$store.dispatch(
        "configUsuarios/listar_usuario",
        this.lista_campos.paciente_id
      );
    },
  },
};
</script>
