<template>
  <div class="row justify-content-center" id="imprimir">
    <header class="fixarHeader">
      <img
        src="../../../../../public/imagens/header1.png"
        width="100%"
        alt=""
      />
    </header>
    <div id="corpo">
     
      <div class="col-md-12 text-center">
        <h4>OCORRENCIA {{ lista_campos.created }}</h4>
      </div>
      <div class="col-md-12">
        <p><b>Paciente</b>: {{ paciente.nome_sobrenome }}</p>
        <p><b>Documento:</b> {{ paciente.rg }}</p>
        <p><b>Data nascimento</b>: {{ paciente.data_nascimento }}</p>
        <p>
          <b>Sexo</b>:
          {{
            paciente.sexo_id === 4
              ? "Feminino"
              : paciente.sexo_id === 3
              ? "Masculino"
              : "no declarado"
          }}
        </p>
      </div>
      <h4 class="text-center" v-if="lista_campos.tipo_ocorrencia_id == 1">
        Triagem
      </h4>
      <h4 v-if="lista_campos.tipo_ocorrencia_id == 2">Consulta</h4>
      <h4 v-if="lista_campos.tipo_ocorrencia_id == 3">Observación</h4>
      <h4 class="text-center" v-if="lista_campos.tipo_ocorrencia_id == 4">
        Receita
      </h4>

      <!-- DADOS RECEITA -->
      <div v-if="lista_campos.tipo_ocorrencia_id == 4">
        <div class="row justify-content-center">
          <div class="col-md-12 col-12">
            <p>Medico(a): {{ lista_campos.nome_sobrenome }}</p>
          </div>

          <div class="col-md-12 col-11">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              "
            >
              <thead>
                <tr class="fw-bold fs-6 text-gray-800">
                  <th>Medicamento</th>
                  <th>Via Administracion</th>
                  <th>Instruciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(l, index) in lista_campos.medicamentos_receita"
                  :key="index"
                >
                  <td>{{ l.medicamento }}</td>
                  <td>{{ l.via_administracao }}</td>
                  <td>{{ l.instrucao }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 text-center assinatura">
        _______________________________
        <p>Responsable</p>
      </div>
    </div>
    <div class="pt-6 fixarRodape">
      <img
        src="../../../../../public/imagens/rodape1.png"
        width="100%"
        alt=""
      />
    </div>
  </div>
</template>
<style>
  .yellow {
    background-color: rgb(255, 255, 189);
    color: rgb(224, 224, 0);
  }
  @media print {
    /* esconder tudo */
    @page {
      background-color: rgb(255, 255, 255);
    }
    body * {
      visibility: hidden;
    }
    /* exibir somente o que tem na div para imprimir */
    #imprimir,
    #imprimir * {
      visibility: visible;
      font-size: 17px;
      width: 1150px; 
  
    }
    p * {
      font-size: 14px;
    }
    .h4 {
      text-align: center;
    }
  
    #imprimir {
      position: fixed;
      left: 0; 
      top: 0;
      background-color: white;
      height: 70cm; 
      width: 100%;
    }
  
    .assinatura {
      padding-top: 10px;
      width: 100%;
      bottom: 300px;
      position: fixed;
    }
    .fixarRodape {
      bottom: 0;
      position: fixed;
    }
    .fixarHeader {
      top: 8px;
      position: fixed;
    }
    #corpo {
      top: 10px;
      padding-top: 20%;
      width: 90%;
      align-items: center;
      align-content: center; 
    }
  
    /* .fixarHeader {
        top: 0;
        width: 100%;
        position: fixed;
    } */
  }
  </style>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  //  props:{

  //  },
  data() {
    return {
      verif: false,
      classe: "",
      form: {
        temperatura: "",
        freq_cardiaca: "",
        freq_respiratoria: "",
        peso: "",
        altura: "",
        pressao: "",
        saturacao: "",
        motivo: "",
        grau_urgencia_id: "",
        tipo_ocorrencia_id: "",
        atendimento_id: "",
        glicemia: "",
        lista: {
          text: "",
        },
      },
    };
  },
  created() {
    this.lista_usuarios();
    this.form = this.lista_campos;
    var data = this.paciente.data_nascimento;
    console.log(data.getMonth);
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    paciente() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
  },

  methods: {
    async lista_usuarios() {
      await this.$store.dispatch(
        "configUsuarios/listar_usuario",
        this.lista_campos.paciente_id
      );
    },
    idade(ano_aniversario, mes_aniversario, dia_aniversario) {
      var d = new Date(),
        ano_atual = d.getFullYear(),
        mes_atual = d.getMonth() + 1,
        dia_atual = d.getDate(),
        ano_aniversario = +ano_aniversario,
        mes_aniversario = +mes_aniversario,
        dia_aniversario = +dia_aniversario,
        quantos_anos = ano_atual - ano_aniversario;

      if (
        mes_atual < mes_aniversario ||
        (mes_atual == mes_aniversario && dia_atual < dia_aniversario)
      ) {
        quantos_anos--;
      }

      return quantos_anos < 0 ? 0 : quantos_anos;
    },
  },
};
</script>
