<template>
    <div>
        <!-- <treeselect :load-options="loadOptions" :options="options" :auto-load-root-options="false" :multiple="true"
            placeholder="Open the menu..." />
            <treeselect-input value="cefa" /> -->

        <!-- <treeselect   :multiple="true" :options="options" /> -->

        <input type="text" class="form-control" v-model="searchText" @input="filterOptions">

        <ul v-if="filteredOptions.length">
            <li v-for="(option, index) in filteredOptions" :key="index" @click="selectOption(index)"
                @mouseenter="highlightOption(index, true)" @mouseleave="highlightOption(index, false)"
                @keydown.enter.prevent="selectOption(index)" @keydown.down.prevent="navigateOptions(index, 'down')"
                @keydown.up.prevent="navigateOptions(index, 'up')"
                :style="{ backgroundColor: highlightedIndex === index ? '#f0f0f0' : 'transparent' }">
                {{ option.label }}
            </li>
        </ul>
        <div v-if="selectedOptions.length">
            <span v-for="(option, index) in selectedOptions" :key="index" class="label label-inline label-light-info">{{
            option.label }}
                <button class="btn btn-sm" @click="remover(index)"> <i class="fa fa-times m-0 p-0 "></i> </button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            options: [
                { id: 1, label: "Fiebre" },
                { id: 2, label: "Cefaleia" },
                { id: 3, label: "Dolor de Garganta" },
                { id: 4, label: "Dolor de espalda" },
                { id: 5, label: "Colica generalizada" },
                { id: 5, label: "Palpitaciones" },
                { id: 5, label: "Insomnio" },
            ],
            filteredOptions: [],
            selectedOptions: [],
            searchText: '',
            highlightedIndex: -1
        };
    },
    methods: {
        filterOptions() {
            this.filteredOptions = this.options.filter(option =>
                option.label.toLowerCase().includes(this.searchText.toLowerCase())
            );
        },
        selectOption(index) {
            this.selectedOptions.push(this.filteredOptions[index]);
            this.searchText = ''; // Clear the search input after selection
            this.filteredOptions = []; // Clear the filtered options
        },
        highlightOption(index, shouldHighlight) {
            if (shouldHighlight) {
                this.highlightedIndex = index;
            } else {
                this.highlightedIndex = -1;
            }
        },
        navigateOptions(index, direction) {
            if (direction === 'down' && index < this.filteredOptions.length - 1) {
                this.highlightedIndex = index + 1;
            } else if (direction === 'up' && index > 0) {
                this.highlightedIndex = index - 1;
            }
        },

        remover(index) {
            this.selectedOptions.splice(index, 1);
        }
    }
};
</script>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
}

li {
    cursor: pointer;
}

span {
    margin-right: 5px;
    padding: 2px 5px;
    background-color: #f0f0f0;
    border-radius: 3px;
}
</style>