<template>
  <div>
    <h5 class="mb-0">Medicamentos retirados pelo paciente</h5>

    <div class="pt-6">
      <b-table
        :fields="[
          'data',
          'produto',
          'codigo',
          'quantidade',
          'lote',
          'ocorrencia',
          'descricao',
          'funcionario',

          'acoes',
        ]"
        :items="lista_saida_produtos"
        :per-page="perPage"
        :current-page="currentPage"
        id="saida_produto"
        class="
          table
          table-head-custom
          table-vertical-center
          table-head-bg
          table-borderless
          table-responsive
        "
        show-empty
        empty-text="Nenhum registro encontrado!"
      >
        <template #head(acoes)><span></span></template>
        <template #cell(acoes)="{item}">
          <div class="text-right w-100">
            <button
              v-show="lista_permissoes_filial.lock_Sala"
              @click="confirm('desfazer a saída',item)"
              class="btn btn-icon btn-light btn-sm mx-1"
              v-b-tooltip.hover
              title="Desfazer saida"
            >
              <i class="fas fa-unlock-alt text-danger"></i>
            </button>
          </div>
        </template>
        <template #cell(ocorrencia)="{ item }">
          <div class="text-right w-100">
            <span
              class="label label-lg label-inline"
              :class="{
                'label-light-info': item.ocorrencia === 1,
                'label-light-primary': item.ocorrencia === 2,
                'label-light-warning': item.ocorrencia === 3,
                'label-light-success': item.ocorrencia === 4,
              }"
            >
              {{
                item.ocorrencia === 1
                  ? "Triagem"
                  : item.ocorrencia === 2
                  ? "Consulta"
                  : item.ocorrencia === 3
                  ? "Internação"
                  : item.ocorrencia === 4
                  ? "Receita"
                  : "Doação"
              }}
            </span>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="lista_saida_produtos.length"
        :per-page="perPage"
        aria-controls="saida_produto"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ocorrencia" }]);
  },
  created() {
    this.listar_saida_produtos();
  },
  computed: {
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_campos() {
      //esta recebendo o id do atendimento aqui
      return this.$store.state.configEmpresa.lista_campos;
    },
  },
  methods: {
    async listar_saida_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch(
          "produto/listar_saida_produtos_atendimento",
          this.lista_campos
        )
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("produto/listar_produto", "");

          // localStorage.removeItem('produto');
        });
    },
    atualizar(value) {
      this.$router.push({
        name: "createSaidaProduto",
        params: { saida_produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(text, value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + text,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (text == "desfazer a saída") this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("produto/desfazer_saida_produto", value.id);
      this.listar_saida_produtos()
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
      
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>