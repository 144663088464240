<template>
  <div
    class="row justify-content-center"
    id="imprimir"
    style="background-color: white"
  >
    <header class="col-md-12 fixarHeader">
      <img
        src="../../../../../public/imagens/header2.png"
        width="100%"
        alt=""
      />
    </header>
    <div id="corpo" class="justify-content-center">
      <div class="col-md-12 text-center">
        <h4>FICHA DEL PACIENTE</h4>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <p><b>Paciente</b>: {{ paciente.nome_sobrenome }}</p>

            <p>
              <b>Sexo</b>:
              {{
                paciente.sexo_id === 4
                  ? "Femenino"
                  : paciente.sexo_id === 3
                  ? "Masculino"
                  : "No declarado"
              }}
            </p>
          </div>
          <div class="col-md-6">
            <p><b>Documento:</b> {{ paciente.rg }}</p>
            <p>
              <b>Sexo</b>:
              {{
                paciente.sexo_id === 4
                  ? "Femenino"
                  : paciente.sexo_id === 3
                  ? "Masculino"
                  : "No declarado"
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="pt-2"
        v-for="lista in lista_campos"
        :key="lista.id"
        :value="lista.id"
      >
        <h3 class="text-center" v-if="lista.tipo_ocorrencia_id == 1">
          Triage {{ lista.created }}
        </h3>
        <h3 class="text-center" v-if="lista.tipo_ocorrencia_id == 2">
          Consulta Medica {{ lista.created }}
        </h3>
        <h3 class="text-center" v-if="lista.tipo_ocorrencia_id == 3">
          Observación {{ lista.created }}
        </h3>
        <h3 class="text-center" v-if="lista.tipo_ocorrencia_id == 4">
          Receta Medica {{ lista.created }}
        </h3>

        <!-- DADOS TRIAGEM -->
        <div v-if="lista.tipo_ocorrencia_id == 1" class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <p><b>Fecha Realización:</b> {{ lista.ocorrencia.created }}</p>
            </div>
            <div class="col-md-12">
              <p><b>Motivo:</b>{{ lista.ocorrencia.motivo }}</p>
            </div>
            <div class="col-md-4 col-4">
              <b>Grado de Urgencia:</b>
              <span
                style="font-size: 15px"
                class="label label-lg label-inline"
                :class="{
                  'label-light-success':
                    lista.ocorrencia.grau_urgencia_id === 1,
                  yellow: lista.ocorrencia.grau_urgencia_id === 2,
                  'label-light-warning':
                    lista.ocorrencia.grau_urgencia_id === 3,
                  'label-light-danger': lista.ocorrencia.grau_urgencia_id === 4,
                }"
              >
                {{
                  lista.ocorrencia.grau_urgencia_id === 2
                    ? "Amarillo"
                    : lista.ocorrencia.grau_urgencia_id === 3
                    ? "Naranja"
                    : lista.ocorrencia.grau_urgencia_id === 4
                    ? "Rojo"
                    : "Verde"
                }}
              </span>
              <p style="padding-top: 5px">
                <b> Peso(Kg): </b> {{ lista.ocorrencia.peso }}
              </p>
              <p><b>Glicemia(mg/dL): </b> {{ lista.ocorrencia.glicemia }}</p>
            </div>
            <div class="col-md-4 col-4">
              <p><b>Saturación(Sp02): </b> {{ lista.ocorrencia.saturacao }}</p>

              <p>
                <b>Presión Arterial(mmHg): </b>{{ lista.ocorrencia.pressao }}
              </p>
            </div>
            <div class="col-md-4 col-4">
              <p>
                <b>Frecuencia cardiaca(bpm): </b>
                {{ lista.ocorrencia.freq_cardiaca }}
              </p>
              <p>
                <b>Frecuencia respiratoria(ipm):</b>
                {{ lista.ocorrencia.freq_respiratoria }}
              </p>
              <p><b>Temperatura: </b> {{ lista.ocorrencia.temperatura }}</p>
            </div>
          </div>
        </div>

        <!-- DADOS CONSULTA -->
        <div v-if="lista.tipo_ocorrencia_id == 2" class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <p>Motivo:{{ lista.ocorrencia.motivo }}</p>
            </div>

            <div class="col-md-12 col-12">
              <p>Alergias: {{ lista.ocorrencia.alergias }}</p>
            </div>

            <div class="col-md-12 col-12">
              <p>Antecedentes A.E.A: {{ lista.ocorrencia.antecedentes_aea }}</p>
              <p>Antecedentes A.P.F: {{ lista.ocorrencia.antecedentes_apf }}</p>
              <p>
                Antecedentes A.P.P:
                {{ lista.ocorrencia.antecedentes_app }}
              </p>
              <p>Antecedentes AREA: {{ lista.ocorrencia.antecedentes_area }}</p>
              <p>
                Antecedentes Quirúrgicos:
                {{ lista.ocorrencia.antecedentes_cirurgicos }}
              </p>
              <p>Examen Físico: {{ lista.ocorrencia.examen_fisico }}</p>
              <p>Diagnóstico: {{ lista.ocorrencia.diagnostico }}</p>
              <p>Conducta: {{ lista.ocorrencia.conduta }}</p>
            </div>
          </div>
        </div>

        <!-- DADOS RECEITA -->
        <div v-if="lista.tipo_ocorrencia_id == 4" class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-12 col-12">
              <p>Medico(a): {{ lista.nome_sobrenome }}</p>
            </div>

            <div class="col-md-11 col-11">
              <table
                class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-borderless
                "
              >
                <thead>
                  <tr class="fw-bold fs-6 text-gray-800">
                    <th>Medicamento</th>
                    <th>Via Administración</th>
                    <th>Instrucciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(l, index) in lista.medicamentos_receita"
                    :key="index"
                  >
                    <td>{{ l.medicamento }}</td>
                    <td>{{ l.via_administracao }}</td>
                    <td>{{ l.instrucao }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center" style="padding-top: 100px">
        _______________________________
        <p>Responsable</p>
      </div>
    </div>
    <div class="col-md-12 pt-6 fixarRodape">
      <img
        src="../../../../../public/imagens/rodape1.png"
        width="100%"
        alt=""
      />
    </div>
    
  </div>
</template>
<style>
.yellow {
  background-color: rgb(255, 255, 189);
  color: rgb(224, 224, 0);
}
@media print {
  /* esconder tudo */
  @page {
    background-color: rgb(255, 255, 255);
  }
  body * {
    visibility: hidden;
  }
  /* exibir somente o que tem na div para imprimir */
  #imprimir,
  #imprimir * {
    visibility: visible;
    font-size: 17px;
    width: 1150px; 

  }
  p * {
    font-size: 14px;
  }
  .h4 {
    text-align: center;
  }

  #imprimir {
    position: fixed;
    left: 0; 
    top: 0;
    background-color: white;
    height: 70cm; 
    width: 100%;
  }

  .assinatura {
    padding-top: 10px;
    width: 100%;
    bottom: 300px;
    position: fixed;
  }
  .fixarRodape {
    bottom: 0;
    position: fixed;
  }
  .fixarHeader {
    top: 8px;
    position: fixed;
  }
  #corpo {
    top: 10px;
    padding-top: 20%;
    width: 90%;
    align-items: center;
    align-content: center; 
  }

  /* .fixarHeader {
      top: 0;
      width: 100%;
      position: fixed;
  } */
}
</style>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { isThisMonth } from "date-fns/esm";

export default {
  mixins: [fireAlert],
  //  props:{

  //  },
  data() {
    return {
      verif: false,
      classe: "",
      form: {
        temperatura: "",
        freq_cardiaca: "",
        freq_respiratoria: "",
        peso: "",
        altura: "",
        pressao: "",
        saturacao: "",
        motivo: "",
        grau_urgencia_id: "",
        tipo_ocorrencia_id: "",
        atendimento_id: "",
        glicemia: "",
        lista: {
          text: "",
        },
      },
    };
  },
  created() {
    this.lista_usuarios();
    this.form = this.lista_campos;
    console.log(this.lista_campos);
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    paciente() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.ocorrencia.mensagem_alert;
    },
  },

  methods: {
    async lista_usuarios() {
      await this.$store.dispatch(
        "configUsuarios/listar_usuario",
        this.lista_campos[0].paciente_id
      );
    },
  },
};
</script>
